import React from "react"

function Visio({ className, ...props }) {
  return (
    <svg
      width={16}
      height={16}
      fill="#527693"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={className}
    >
      <path
        d="M20.8 7.3c-.8-.5-1.8-.7-2.7-.4l-2.3.8C15.2 6.1 13.7 5 12 5H6C3.8 5 2 6.8 2 9v6c0 2.2 1.8 4 4 4h6c1.7 0 3.2-1.1 3.8-2.7l2.3.8c.3.1.6.2 1 .2.6 0 1.2-.2 1.7-.6.8-.6 1.2-1.5 1.2-2.4V9.8c0-1-.5-1.9-1.2-2.5zM12 17H6c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2v6c0 1.1-.9 2-2 2zm8-2.8c0 .3-.2.6-.4.8-.3.2-.6.2-.9.1l-2.7-.8V9.7l2.7-.9c.3-.1.6 0 .9.2.2.2.4.4.4.8v4.4z"
        id="_icons"
      />
    </svg>
  )
}

export default Visio
