import React from "react"

function Profile({ ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#527693"
        fillRule="evenodd"
        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm4.953-4.613a6 6 0 1 0-9.907 0 4.005 4.005 0 0 1 2.282-3.022 3 3 0 1 1 5.344 0 4.005 4.005 0 0 1 2.281 3.022ZM11 13.197V12a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v1.197c.883.51 1.907.803 3 .803a5.972 5.972 0 0 0 3-.803ZM8 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      />
    </svg>
  )
}

export default Profile
