import React from "react"

function BareMail({ ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 32 32"
      fill="#527693"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.448 7.021c2.443 0 4.745 1.083 6.432 2.776v.005c0-.813.547-1.428 1.303-1.428h.192c1.193 0 1.432 1.125 1.432 1.48l.005 12.635c-.083.828.855 1.256 1.376.724 2.025-2.083 4.452-10.719-1.261-15.719-5.328-4.667-12.479-3.896-16.281-1.276-4.041 2.792-6.624 8.959-4.115 14.755 2.74 6.319 10.573 8.204 15.235 6.324 2.36-.953 3.448 2.233.995 3.276-3.697 1.577-14 1.416-18.812-6.917C-1.302 18.027-1.13 8.125 7.496 2.995 14.089-.932 22.788.156 28.032 5.631c5.48 5.729 5.163 16.448-.187 20.615-2.423 1.895-6.021.052-5.995-2.709l-.027-.9c-1.687 1.671-3.932 2.651-6.375 2.651-4.833 0-9.088-4.256-9.088-9.084 0-4.88 4.255-9.181 9.088-9.181zm6.079 8.834c-.183-3.537-2.808-5.667-5.98-5.667h-.12c-3.656 0-5.687 2.88-5.687 6.145 0 3.661 2.453 5.973 5.672 5.973 3.593 0 5.952-2.629 6.124-5.739z" />
    </svg>
  )
}

export default BareMail
